/*
* 업무구분: 고객등록
* 화 면 명: MSPCM015P
* 화면설명: 외국인정보입력
* 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
* 작 성 일: 2022.06.20
* 작 성 자: 이태훈
*/
<template>
  <ur-page-container :show-title="false" type="popup" title="외국인정보등록">
    <ur-box-container direction="column" alignV="start">
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container alignV="start"  componentid="ur_box_container_001" class="ns-sch-area">
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="input-box mb20">
            <ur-text componentid="span_common_text_004" class="title">외국인등록번호</ur-text>
            <!-- <mo-text-field v-model="foreignCustVo.knb" underline readonly/> -->
            <mo-text-field v-model="fn_KnbNo" underline readonly/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="input-box mb20">
            <!-- 성명 -->
            <mo-text-field v-model="foreignCustVo.custEngNm" underline placeholder="영문명을 입력하세요" class="form-input-name"/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="input-box mb20">
            <!-- 국가명 -->
            <mo-text-field v-model="foreignCustVo.natyNm" readonly searchable underline placeholder="국가를 선택해주세요." class="form-input-name" @click-icon="fn_BtnS3OnClick"/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="input-box mb45">
            <!-- 여권번호 -->
            <mo-text-field v-model="foreignCustVo.psprtNo" :rules="validatePsprtNo" underline placeholder="여권번호를 입력하세요" class="form-input-name"/>
            <span class="bottom-txt">여권번호가 없는 경우에는 임시 번호로 저장됩니다<div class=""></div></span>
          </ur-box-container>
          
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="input-box">
            <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="dmclScCd">
                <mo-segment-button value="01">국내</mo-segment-button>
                <mo-segment-button value="02">국외</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue"
                @click="fn_CloseConfirm2()">확인</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </mo-validate-watcher>
    </ur-box-container>

    <!-- 국가선택 안내 bottomSheet -->
    <mo-bottom-sheet ref="natyCnfrmPop" class="ns-bottom-sheet ns-style3">
      <div>
      <p class="cancel-ment" v-html="natyMsg"></p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_NatyCnfrmPop('Close')">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <mo-bottom-sheet ref="modal2" class="ns-bottom-sheet ns-style3">
      <div>
      <p class="cancel-ment" v-html="message2"></p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnNegative2()">취소</mo-button>
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ShowBpConfirm()">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPCM017P from '@/ui/cm/MSPCM017P.vue'
import MSPCM022P from '@/ui/cm/MSPCM022P.vue'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM015P",
    screenId: "MSPCM015P",
    components: {
      MspBottomSelect,
      MSPCM017P,
      MSPCM022P
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    props: {
      tempCustCardVo: Object
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.foreignCustVo = this.$bizUtil.cloneDeep(this.tempCustCardVo)
      this.foreignCustVo.custEngNm = this.tempCustCardVo.custNm

      if (this.foreignCustVo.dmclScCd !== undefined && this.foreignCustVo.dmclScCd.trim() !== '') {
        this.dmclScCd = this.foreignCustVo.dmclScCd
      } else {
        this.dmclScCd = '01'
      }
    },
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.$bizUtil.insSrnLog('MSPCM015P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        validatePsprtNo: [
          v =>  this.fn_WatcherValid() || this.watcherValidMsg
        ],
        foreignCustVo: 
        {
          knbFrno: '',
          knbBkFstNo: '',
          custEngNm: '',
          natyNm: '',
          natyCm: '',
          psprtNo: '',
          dmclScCd: ''
        },
        pSelected_natyCd: {}, // 국적 선택
        dmclScCd: '',
        natyMsg: '', // 국가선택 시 안내 메시지
        watcherValidMsg: '', // Watcher Type Valid Msg
        message: '',
        message2: '고객정보가 정확하게 입력되었음을 확인하셨습니까?'
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {      
      fn_KnbNo: { // 외국인등록번호 앞자리 + 성별 + ******
        get: function () {
          let lv_KnbNo = ''

          if( !this.$bizUtil.isEmpty(this.foreignCustVo.knbFrno) && !this.$bizUtil.isEmpty(this.foreignCustVo.knbBkFstNo)){
            lv_KnbNo = this.foreignCustVo.knbFrno + '-' + this.foreignCustVo.knbBkFstNo + '******'
          }
           return lv_KnbNo
        },
        set: function (data) {
        }
      },
      modal(){
        return this.$refs.modal;
      },
      modal2(){
        return this.$refs.modal2;
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_ShowBpConfirm
      * 설명       : 외국인정보 저장 컨펌창 확인 버튼 클릭
      ******************************************************************************/
      fn_ShowBpConfirm () {
        this.modal2.close()
        this.fn_ShowConfirm()
      },
      /******************************************************************************
      * Function명 : fn_ShowConfirm
      * 설명       : 외국인정보 저장 컨펌창 확인 버튼 클릭
      ******************************************************************************/
      fn_ShowConfirm () {
        // let lv_RetVal = this.fn_Validation()
        // if (lv_RetVal) {
        let foreignCust = {}
        let bpForeignCust = {}
        let lv_Vm = this

        lv_Vm.foreignCustVo.dmclScCd = lv_Vm.dmclScCd
        lv_Vm.foreignCustVo.dmclScCdNm = (lv_Vm.dmclScCd === '01')? '국내' : '해외'
        lv_Vm.foreignCustVo.bpDmclScCdNm = lv_Vm.foreignCustVo.dmclScCdNm

        // 여권번호 입력값 없을 경우 임시여권번호로 셋팅
        if (lv_Vm.foreignCustVo.psprtNo === undefined || lv_Vm.foreignCustVo.psprtNo.trim() === '') {
          lv_Vm.foreignCustVo.psprtNo = 'IMSI' + lv_Vm.foreignCustVo.knb.substring(0, 6)
        }

        // 영문명 소문자->대문자로 변환
        const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
        lv_Vm.lv_FilterSearchCustNm = lv_Vm.foreignCustVo.custEngNm
        if (validOnlyEnglish(lv_Vm.lv_FilterSearchCustNm)) {
          lv_Vm.foreignCustVo.custEngNm = lv_Vm.lv_FilterSearchCustNm.toUpperCase()
        }

        foreignCust.custEngNm = lv_Vm.foreignCustVo.custEngNm
        foreignCust.natyCd = lv_Vm.foreignCustVo.natyCd
        foreignCust.psprtNo = lv_Vm.foreignCustVo.psprtNo
        foreignCust.dmclScCd = lv_Vm.foreignCustVo.dmclScCd

        // bp정보
        bpForeignCust.custEngNm = lv_Vm.foreignCustVo.bpCustEngNm
        bpForeignCust.natyCd = lv_Vm.foreignCustVo.bpNatyCd
        bpForeignCust.psprtNo = lv_Vm.foreignCustVo.bpPsprtNo
        bpForeignCust.dmclScCd = lv_Vm.foreignCustVo.bpDmclScCd

        let tCheck = false
        for (let i in Object.keys(bpForeignCust)) {
          if(typeof bpForeignCust[Object.keys(bpForeignCust)[i]] !== 'undefined'){
            if (bpForeignCust[Object.keys(bpForeignCust)[i]].trim() !== '') {
              tCheck = true
              break
            }
          }
        }
        if (tCheck) {
          if (JSON.stringify(foreignCust) !== JSON.stringify(bpForeignCust)) {
            /*
            lv_Vm.message = '아래와 같이 기존에 등록된 정보와 상이합니다.\n'
            lv_Vm.message += '그래도 등록하시겠습니까?\n'
            lv_Vm.message += '- 영문명 : ' + lv_Vm.foreignCustVo.bpCustEngNm + '\n'
            lv_Vm.message += '- 국적 : ' + lv_Vm.foreignCustVo.bpNatyNm + '\n'
            lv_Vm.message += '- 여권번호 : ' + lv_Vm.foreignCustVo.bpPsprtNo + '\n'
            lv_Vm.message += '- 거주구분 : ' + lv_Vm.foreignCustVo.bpDmclScCdNm + '\n'
            lv_Vm.modal.open()
            */
            lv_Vm.fn_ShowMessage() // MSPCM022P 팝업창 호출
          } else {
            lv_Vm.$emit('confirmPopup', lv_Vm.foreignCustVo)
          }
        } else {
          lv_Vm.$emit('confirmPopup', lv_Vm.foreignCustVo)
        }
        // }
      },
      /******************************************************************************
        * Function명 : fn_Validation
        * 설명       : 인증번호 버튼 클릭 시 유효성 체크
        ******************************************************************************/
        fn_Validation () {
          let lv_RetVal = true

          if (this.foreignCustVo.custEngNm === null || this.foreignCustVo.custEngNm.trim() === '') {
            this.getStore('confirm').dispatch('ADD', '고객명을 입력하세요.')
            lv_RetVal = false
            return lv_RetVal
          } else if (!this.$bizUtil.isCustNameValidation(this.foreignCustVo.custEngNm.trim(), 'ZPER02')) {
            this.getStore('confirm').dispatch('ADD', '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)')
            lv_RetVal = false
            return lv_RetVal
          } else if (this.$bizUtil.isEmpty(this.foreignCustVo.natyNm)) {
            this.getStore('confirm').dispatch('ADD', '국가코드를 선택하세요.')
            lv_RetVal = false
            return lv_RetVal
          } else if (!this.fn_WatcherValid()) {
            this.getStore('confirm').dispatch('ADD', this.watcherValidMsg)
            lv_RetVal = false
            return lv_RetVal
          }
          return lv_RetVal
        },
        /******************************************************************************
      * Function명 : fn_CloseConfirm2
      * 설명       : 101P 팝업 확인 버튼 클릭
      ******************************************************************************/
      fn_CloseConfirm2 () {
        // this.modal2.open()
        let lv_RetVal = this.fn_Validation()

        if (lv_RetVal) {
          this.modal2.open()
        }
      },
      /******************************************************************************
      * Function명 : fn_BtnS3OnClick
      * 설명       : 국가코드 팝업 창 show
      ******************************************************************************/
      fn_BtnS3OnClick () {
        const lv_Vm = this
        this.popup017 = this.$bottomModal.open(MSPCM017P,{
          properties: {
            title: '국가선택', // 팝업의 타이틀 셋팅
            natyNm: lv_Vm.foreignCustVo.natyNm
          },
          listeners: {
            // 팝업 닫히면서 실행되는 함수
            confirmPopup: (param) => {
              lv_Vm.$bottomModal.close(lv_Vm.popup017)
              /* 유효성 체크 추가 */
              if (param.natyCd === 'EU' || param.natyCd === 'NT' || param.natyCd === 'UN') {
                let lv_Msg = param.natyNm + '은(는) 국제표준(ISO) 국적코드가 아니므로 저장이 불가능합니다. 지점 담당자에게 문의 부탁드립니다.'
                lv_Vm.natyMsg = lv_Msg
                lv_Vm.fn_NatyCnfrmPop('Open')
                return
              } else if (param.natyCd === 'KP' || param.natyCd === 'KR') {
                let lv_Msg = param.natyNm + '은 국가 선택이 불가합니다. (문의: 컴플라이언스팀)'
                lv_Vm.natyMsg = lv_Msg
                lv_Vm.fn_NatyCnfrmPop('Open')
                return
              }

              lv_Vm.foreignCustVo.natyCd = param.natyCd
              lv_Vm.foreignCustVo.natyNm = param.natyNm
            }
          }
        })
      },
      /******************************************************************************
       * Function명: fn_OnNegative2
       * 설명: Confirm창 취소 버튼 클릭 함수
       ******************************************************************************/
      fn_OnNegative2 () {
        // confirm 창 닫음
        this.modal2.close()
      },
      /******************************************************************************
      * Function명 : fn_ShowMessage
      * 설명       : 정보 수정 비교 팝업
      ******************************************************************************/
      fn_ShowMessage () {
        const lv_Vm = this
        this.popup022 = this.$bottomModal.open(MSPCM022P,{
          properties: {
            title: '알림', // 팝업의 타이틀 셋팅
            objTmp: lv_Vm.foreignCustVo
          },
          listeners: {
            // 팝업 닫히면서 실행되는 함수
            confirmPopup: (param) => {
              lv_Vm.$bottomModal.close(this.popup022)
              lv_Vm.$emit('confirmPopup', lv_Vm.foreignCustVo)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popup022)
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_NatyCnfrmPop
      * 설명       : 이용동의 안내 
      *           - Open(컨펌팝업열기), Close(창닫기) 선택에 대한 화면 이동
      ******************************************************************************/
      fn_NatyCnfrmPop (param) {
        
        switch(param){
          case 'Open':
            this.$refs.natyCnfrmPop.open()
            break
          case 'Close':
            this.$refs.natyCnfrmPop.close()
            break
        }
        
      },
      /******************************************************************************
      * Function명 : fn_WatcherValid
      * 설명       : Watcher Type Validtaion
      ******************************************************************************/
      fn_WatcherValid () {
        let lv_ResFlg = true
        this.watcherValidMsg = ''
        if( !this.$bizUtil.isEmpty(this.foreignCustVo) && !this.$bizUtil.isEmpty(this.foreignCustVo.psprtNo)){
          //여권번호 1자리 입력 시, 5자리 이하 16이상 일 경우 
          if(this.foreignCustVo.psprtNo.length >= 1 && ( this.foreignCustVo.psprtNo.length <= 5 || this.foreignCustVo.psprtNo.length >= 16)) {
            this.watcherValidMsg = '여권번호는 6자리 이상 15자리 이하여야 합니다.'
            lv_ResFlg = false
            return lv_ResFlg
          }
        }
        return lv_ResFlg
      } 
    }
  };
</script>
<style scoped>
</style>