/*
* 업무구분: 고객등록 (MSPCM017P)
* 화면 명: 국가선택팝업
* 화면 설명: 국가선택팝업
* 화면 접근권한:
*/
<template data-page-screen-size="300x500">
  <ur-page-container title="국가선택" :show-title="false" type="popup">
    <ur-box-container direction="column" componentid="ur_box_container_001" align-v="start" class="ns-bottom-sheets">
      <!--
      <ur-box-container align-v="center" componentid="ur_box_container_002" direction="row" ref="_modal__title" class="ns-bs-title-login no-title">
        <div class="title"><ur-text componentid="span_common_text_003" class size="lg">{{ title }}</ur-text></div>
        <div class="btn-close">X</div>
      </ur-box-container>
      -->
      <ur-box-container align-v="start" componentid="ur_box_container_003" direction="column" align-h="start" class="ns-bs-contents select">
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="select-area">
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="sch-box">
            <mo-text-field v-model="searchKeyword" searchable underline placeholder="국가코드 또는 국가명으로 검색"  @click-icon="fn_Submit" @focus="fn_AplyCSS" clearable/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-info-sum">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="sum">총 {{natyCd.length}}건</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          <ur-box-container direction="column" alignV="start" componentid="ur_box_container_007" class="ns-radio-list2">
            <mo-list :list-data="natyCd">
              <template #list-item="{item}">
                <mo-list-item>
                  <mo-radio v-model="selected_natyCd" :value="item.value" @input="fn_CheckSelectedItem(item)" />
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span>{{item.value}}</span><em>|</em><span>{{item.text}}</span>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
        <!-- <ur-text componentid="span_common_text_002" size="lg">{{content}}</ur-text> -->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  export default {
    data() {
      return {
        selected_natyCd: null, // 국적 선택
        natyCd: [], // 국적코드리스트
        copyNatyCd: [], // 국적리스트 복사 리스트
        searchKeyword: ''
      }
    },
    modalProps: {
      full: false
    },
    props: {
      title: {
        type: String,
        default: "국가선택"
      },
      natyNm: String
    },
    created () {
      this.fn_Init()
    },
    mounted () {
      this.$bizUtil.insSrnLog('MSPCM017P')
    },
    beforeRouteLeave () {
      // CSS 부여 삭제
      document.getElementsByClassName('mo-bottom-sheet__wrapper mo-bottom-sheet--open')[2].style.top = ''
    },
    computed: {},
    methods: {
      /******************************************************************************
      * Function명 : fn_Init
      * 설명       : 초기 데이터 셋팅
      ******************************************************************************/
      fn_Init () {
        let lv_Vm = this
        lv_Vm.natyCd = []
        this.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD').then(function () { // 국적조회 공통코드 세팅
          let lv_NatyCd = lv_Vm.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')
          if (lv_NatyCd.length > 0) {
            lv_NatyCd.forEach(
              (item) => {
                let lv_Item = {}
                lv_Item.value = item.key
                lv_Item.text = item.label
                lv_Vm.natyCd.push(lv_Item)
              }
            )
          }
          lv_Vm.copyNatyCd = lv_Vm.$bizUtil.cloneDeep(lv_Vm.natyCd) // 국적코드 리스트 copy

          if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.natyNm )) {
            lv_Vm.searchKeyword = lv_Vm.natyNm
            lv_Vm.fn_Submit()
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_Submit
      * 설명       : 국적 조회 (국적 or 코드)
      ******************************************************************************/
      fn_Submit () {
        let lv_Keyword = 'NAME'
        let regexp_eng = '[a-zA-Z]' // 영문(대소)
        if (this.searchKeyword.length == 2 && this.searchKeyword.search(regexp_eng) > -1) {
          lv_Keyword = 'CODE'
        }
        let lv_Vm = this
        let lv_natyCdList = this.copyNatyCd.filter(
          (item) => {
            if (lv_Keyword === 'CODE') { // 국가코드로 검색하는 경우
              let natyCdKeyword = lv_Vm.searchKeyword.toUpperCase()
              if (item.value.indexOf(natyCdKeyword) > -1) {
                return item
              }
            } else { // 국가명으로 검색하는 경우
              if (item.text.indexOf(lv_Vm.searchKeyword) > -1) {
                return item
              }
            }            
          }
        )
        this.natyCd = this.$bizUtil.cloneDeep(lv_natyCdList) // 국적코드 리스트 copy
      },
      /******************************************************************************
      * Function명 : fn_CheckSelectedItem
      * 설명       : 국적 선택 시 호출
      ******************************************************************************/
      fn_CheckSelectedItem (item) {
        this.selected_natyCd = item.value
        this.$emit('confirmPopup', {natyCd: item.value, natyNm: item.text})
      },

      /* ------------------------------------------- CSS 처리 ------------------------------------------- */
      // CSS 부여
      fn_AplyCSS () {
        // 키패드 노출로 인한 CSS 처리
        let elList = document.getElementsByClassName('mo-bottom-sheet__wrapper mo-bottom-sheet--open')
        elList[elList.length-1].style.top = '100px'
      }
    }
  };
</script>
<style scoped>
  [componentid="ur_box_container_008"] {
    height: 140px;
  }
</style>
<style lang="scss">
</style>