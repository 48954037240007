/*
 * 업무구분 : 외국인 신분증 진위 확인
 * 화면 명  : MSPCM019P
 * 화면 설명: 외국인 신분증 진위 확인
 * 개발자: 송웅주
 * 개발날짜: 2022.09.01
 */
<template>
  <ur-page-container :show-title="false" type="popup" title="외국인신분증진위여부">
    <ur-box-container direction="column" alignV="start">
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area">
            <span class="sch-title">외국인등록정보</span>

            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="sch-box">
              <div class="verify-birth ns-style2">
                <!-- 외국인등록번호 앞자리-->
                <mo-text-field v-model="knbFrno" readonly underline class="birth-input form-input-name" placeholder="외국인등록번호" />
                <span>-</span>
                <!-- 외국인등록번호 뒷자리 7자리-->
                <mo-text-field v-model="knbBknoMask" readonly underline class="birth-input2 form-input-name" placeholder="0"/>
                <!--
                <mo-text-field v-if="local" v-model="knbBkno" underline class="birth-input2 form-input-name" placeholder="0"/>
                <m-trans-key-input v-else v-model="knbBkno"  :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType" class="birth-input3"/>
                -->
              </div>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="sch-box">
              <mo-text-field class="form-input-name" v-model="foreignCustVo.custEngNm" underline placeholder="영문명을 입력하세요" />
            </ur-box-container>
            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="sch-box">
              <div class="ns-date-picker">
                <mo-date-picker v-model ="issuDt3" placeholder="발급일자"/>
              </div>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="sch-box">
              <div class="verify-birth ns-style2">
                <mo-text-field ref="seriaNoRef" type="number" v-model="lv_SeriaNo" class="form-input-name" placeholder="일련번호" mask="###########"/>
              </div>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="sch-box">
              <span class="txt">
                <span class="txt-ment">※ 2011.1.1 이전에 발급된 등록증은 일련번호 입력할 필요 없음(일련번호 없는 경우)</span>
              </span>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-round-btn ns-style2">
              <mo-button class="ns-btn-round" @click="fn_chkRealFrg">외국인 등록증 진위 확인</mo-button>
            </ur-box-container>

            <ur-box-container v-if="isDevelopment" alignV="start" componentid="ur_box_container_005" direction="column" class="ns-round-btn ns-style2" style="padding: 15px;">
              <mo-button class="ns-btn-round" @click="fn_Pass">외국인 등록증 진위 Pass</mo-button>
            </ur-box-container>
          
            <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel()">취소</mo-button>
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" :disabled="!checkResult" class="ns-btn-round blue" @click="fn_Confirm()">확인</mo-button>
              </div>
            </ur-box-container>  
          </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM019P",
    screenId: "MSPCM019P",
    components: {
      MspBottomSelect,
      // MSPBottomAlert
    },
    props: {
      tempCustCardVo: {
        type: Object
      }
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      if (!this.$bizUtil.isEmpty(this.tempCustCardVo)) {
        this.foreignCustVo = this.$bizUtil.cloneDeep(this.tempCustCardVo)
        this.foreignCustVo.knb = this.tempCustCardVo.knb
        this.knbFrno = this.tempCustCardVo.knbFrno

        this.knbBknoMask = this.tempCustCardVo.knbBkFstNo + '******'

        if (this.tempCustCardVo.isExistCustYN === 'Y') {
          this.knb = this.tempCustCardVo.prextKnb
        } else {
          if (this.$commonUtil.isMobile()) {
            this.knbBkno = this.tempCustCardVo.knbBknoCipher
          } else {
            this.knbBkno = this.tempCustCardVo.knbBkno
          }
        }

        // 영문명에 메인화면의 고객명(custNm) 셋팅
        this.foreignCustVo.custEngNm = this.tempCustCardVo.custNm
      }
    },
    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.$bizUtil.insSrnLog('MSPCM019P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        foreignCustVo: {},
        local: (process.env.NODE_ENV === 'local'),
        issuDt3: '',
        knbFrno: '', // 식별번호 앞자리 (주민등록증)
        knbBkno: '', // 식별번호 뒷자리 (주민등록증)
        knb: '',
        knbEncr: '',
        knbBknoMask: '',
        encKey: '',
        tlgmNo: '999999',
        cntU149: 0,
        checkResult: false,
        checkMsg: '식별번호를 확인해주세요.',
        scrapYn: '', // 진위여부,

        lv_SeriaNo: '', // 일련번호 11자리
        isDevelopment: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2')
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명: fn_getMaskType
      * 설명: ios에서 넘어온 mtranskey key
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_getMaskType (param) {
        this.encKey = param
        this.knbBkno = this.encKey
      },
      fn_chkRealFrg () {
        //this.fnMakeParams('1')
        /*
        if (this.$bizUtil.isEmpty(this.knbBkno)) {
          this.getStore('confirm').dispatch('ADD', '외국인등록번호를 입력해주세요')
          return
        }
        */

        if (this.$bizUtil.isEmpty(this.foreignCustVo.custEngNm)) {
          this.getStore('confirm').dispatch('ADD', '영문명을 입력해주세요')
          return
        }

        if (this.$bizUtil.isEmpty(this.issuDt3)) {
          this.getStore('confirm').dispatch('ADD', '발급일자를 입력해주세요')
          return
        }

        let seriaNo = this.lv_SeriaNo
        if (this.issuDt3 >= '2011.01.01') {
          let regex = /^[0-9]/g
          let alertMsg = ''

          if (this.$bizUtil.isEmpty(seriaNo)) {
            alertMsg = '일련번호 입력 바랍니다'
          } else if (seriaNo.length < 11) {
            alertMsg = '일련번호 11자리를 입력 바랍니다'
          } else if (!regex.test(this.lv_SeriaNo)) {
            alertMsg = '일련번호를 다시 한번 확인 바랍니다'
          }

          if (!this.$bizUtil.isEmpty(alertMsg)) {
            this.getStore('confirm').dispatch('ADD', alertMsg)
            return
          }
        }

        this.fn_CheckSsnValidation() // 주민번호 유효성 체크
      },
      fnMakeParams (type, tlgmNo) {
        let key = '3'
        let params
        let trnstId
        let auth = 'S'

        // this.fnRrnCheckRule(true)
        let lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 컨설턴트 정보

        switch (key) {
          // 외국인등록증
          case '3':
            if (type === '1') {
              trnstId = 'txTSSBC01P5'
              params = {
                auth: lv_UserInfo.userId,
                psNo: this.knbEncr,
                idcdIsuDt: moment(this.issuDt3).format('YYYYMMDD'),
                serialnumber: this.lv_SeriaNo // 일련번호
              }
            } else if (type === '2') {
              trnstId = 'txTSSBC01P6'
              params = {
                auth: lv_UserInfo.userId,
                tlgmNo: tlgmNo,
                serialnumber: this.lv_SeriaNo // 일련번호
              }
            } else {
              trnstId = 'txTSSBC01P7'
              params = {
                auth: lv_UserInfo.userId,
                tlgmNo: tlgmNo
              }
            }
            break
        }
        this.fnServiceCall(params, trnstId, auth)
      },
      fnServiceCall (params, trnstId, auth) {
        window.vue.post(this, params, trnstId, auth)
          .then((response) => {
            this.fnServiceCallHandler(response)
          })
          .catch((error) => {
            window.vue.getStore('progress').dispatch('SUB')
            window.vue.error(error)
          })
      },
      fnServiceCallHandler (response) {
        let body = response.body
        let trnstId = response.trnstComm.trnstId
        let tlgmNo = body.tlgmNo
        let code = body.code
        let message = body.message

        if (code === 'X' || code === 'E') {
          // this.$addSnackbar(code + ': ' + message)
          this.$addSnackbar('신분증 인증에 실패하였습니다.')
          return
        }
        if (trnstId === 'txTSSBC01P1' || trnstId === 'txTSSBC01P3' || trnstId === 'txTSSBC01P5') {
          this.fnMakeParams('2', tlgmNo)
        } else if (trnstId === 'txTSSBC01P2' || trnstId === 'txTSSBC01P4' || trnstId === 'txTSSBC01P6') {
          this.fnMakeParams('3', tlgmNo)
        } else {
          if (message === 'U149' && this.cntU149 < 4) {
            this.cntU149 = this.cntU149 + 1
            this.fnMakeParams('2', tlgmNo)
          } else {
            this.cntU149 = 0
            this.scrapYn = code
            if (code === 'Y') {
              // this.getStore('confirm').dispatch('ADD', '신분증 인증에 성공하였습니다.')
              this.tlgmNo = tlgmNo // 송수신전문관리번호(TTSZ00009) 2021.11.09추가
              this.checkResult = true
              this.$addSnackbar('신분증 인증에 성공하였습니다.')
            // } else if (code === 'P') { // 권한거부
            //   this.getStore('confirm').dispatch('ADD', '신분증 인증에 실패하였습니다. 카메라 실행권한이 없습니다. 권한을 부여해주세요.')
            // } else if (code === 'E') { // 서비스 에러
            //   this.getStore('confirm').dispatch('ADD', '신분증 인증에 실패하였습니다.')
            } else {
              // 기타 예외
              // this.getStore('confirm').dispatch('ADD', '신분증 인증에 실패하였습니다.')
              this.$addSnackbar('신분증 인증에 실패하였습니다.')
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_CheckSsnValidation
      * 설명       : 주민번호 유효성 체크
      ******************************************************************************/
      fn_CheckSsnValidation () {
        /*
        let lv_TmpValidation = false //주민번호(외국인등록번호) 유무 체크
        
        if (this.knbBkno !== null && this.knbBkno.trim() !== '') {
          lv_TmpValidation = true
        }
        */
        
        // 주민번호 앞자리가 입력되었는지, 주민번호 뒷자리가 입력되었는지 확인 후, 유효성 체크
        // if (!this.knbFrno !== null && this.knbFrno.trim() !== '' && lv_TmpValidation) {
        let lv_Vm = this

        // let lv_Knb = (lv_Vm.$commonUtil.isMobile()) ? '' : lv_Vm.knbFrno + lv_Vm.knbBkno // PC일 경우 전체 평문 주민번호를 넘겨줌. 모바일일 경우 공백처리
        let lv_Knb = ''
        if (lv_Vm.tempCustCardVo.isExistCustYN === 'Y') {
          lv_Knb = lv_Vm.knb
        } else {
          if (lv_Vm.$commonUtil.isMobile()) {
            lv_Knb = ''
          } else {
            lv_Knb = lv_Vm.knbFrno + lv_Vm.knbBkno
          }
        }
        this.encKey = this.$commonUtil.getIsIOS() ? 'IOS' : ''

        let pParams =
                      {
                        knb: lv_Knb, // 전체 주민번호
                        knbFrno: lv_Vm.knbFrno, // 주민번호 앞번호
                        knbBknoCipher: lv_Vm.knbBkno, // 주민번호 뒷번호
                        knbScCd: lv_Vm.encKey // ios encrypt key
                      }

        // 2023.05.30 txTSSCM10S6 > txTSSCM10S7 (주민번호 복호화하며 체크하는 서비스로 변경)
        let trnstId = 'txTSSCM10S7'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null) {
              if (response.body.ssnYn === 'Y') {
                lv_Vm.knbEncr = response.body.knbEncr
                lv_Vm.fnMakeParams('1')
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        // }
        // return lv_TmpValidation
      },
      fn_Cancel () {
        this.$emit('onPopupCancel')
      },
      fn_Confirm () {
        this.$emit('onPopupClose')
      },
      fn_Pass () {
        this.checkResult = true
      }
    }
  }
</script>
