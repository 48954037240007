<template data-page-screen-size="300x500">
  <ur-page-container title="New Page" :show-title="false" type="popup">
    <ur-box-container direction="column" componentid="ur_box_container_001" align-v="start">
      
      <ur-box-container align-v="center" componentid="ur_box_container_002" direction="row" ref="_modal__title" class="ns-bs-title-login no-title">
      </ur-box-container>

      <ur-box-container align-v="start" componentid="ur_box_container_003" direction="column" align-h="start" class="ns-bs-contents">
        <p class="foreigner-txt">
          <span>아래와 같이 기존에 등록된 정보와 상이합니다.<br>그래도 등록하시겠습니까?</span>
         - 영문명 : {{foreignCustVo.bpCustEngNm}} <br>
         - 국적 : {{foreignCustVo.bpNatyNm}} <br>
         - 여권번호 : {{foreignCustVo.bpPsprtNo}}<br>
         - 거주구분 : {{foreignCustVo.bpDmclScCdNm}} 
        </p>
        <!-- <ur-text componentid="span_common_text_002" size="lg">{{content}}</ur-text> -->
      </ur-box-container>

      <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
        <div class="relative-div">
          <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_ClickCancel" class="ns-btn-round white">취소</mo-button>
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ClickConfirm" class="ns-btn-round blue">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  export default {
    data() {
      return {
        foreignCustVo: {}
      };
    },
    modalProps: {
      full: false
    },
    props: {
      title: {
        type: String,
        default: "UIDEV003P"
      },
      objTmp: {
        type: Object,
        default: {}
      }
    },
    created() {
      console.log(this.objTmp)
      this.foreignCustVo = this.objTmp
    },
    computed: {},
    methods: {
      fn_ClickConfirm() {
        this.$emit("confirmPopup", "saved");
      },
      fn_ClickCancel() {
        console.log(this.objTmp)
        this.$emit("onPopupClose");
      }
    }
  };
</script>
<style scoped>
  [componentid="ur_box_container_008"] {
    height: 140px;
  }
</style>
<style lang="scss">
</style>